// =============================
// Config
// =============================

// Locales that exist for Admin-v2 & Meta Ingestion
module.exports = {
  languagesList: ['en', 'fr', 'de', 'es', 'ja'],
  defaultLanguage: 'en',
  languagesNames: {
    en: {
      name: 'English',
      nativeName: 'English',
    },
    fr: {
      name: 'French',
      nativeName: 'Français',
    },
    de: {
      name: 'German',
      nativeName: 'Deutsch',
    },
    es: {
      name: 'Spanish',
      nativeName: 'Español',
    },
    ja: {
      name: 'Japanese',
      nativeName: '日本語',
    },
  },
};
