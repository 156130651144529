// =============================
// Imports
// =============================

const NextI18Next = require('next-i18next').default;
const path = require('path');

// =============================
// Config
// =============================

module.exports = new NextI18Next({
  localePath: path.resolve('./public/static/locales'),

  defaultLanguage: 'en',
  otherLanguages: ['fr', 'es', 'de', 'ja'],

  // NOTE: preload sometimes doesn't preload languages,
  // but fallbackLng preloads consistently. So it is used for preloading here
  fallbackLng: ['en', 'fr', 'de', 'es', 'ja'],
  preload: ['en', 'fr', 'de', 'es', 'ja'],
  load: 'languageOnly', // we only provide en, fr -> no region specific locals like en-US, fr-FR

  ns: ['common', 'errors', 'components', 'pages'], // need to preload all the namespaces
  defaultNS: 'common',

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format: (value, format) => {
      if (format === 'uppercase') return value.toUpperCase();
      if (format === 'lowercase') return value.toLowerCase();
      return value;
    },
  },

  debug: false, // process.env.NODE_ENV !== 'production'
  saveMissing: true,

  detection: {
    order: ['cookie'],
    cache: false,
  },
});
